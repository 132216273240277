<template>
  <v-container>
    <v-row align="center" justify="center" class="text-center" no-gutters>
      <v-card width="90%" class="pa-10">
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-img
            src="@/assets/logo_survey.png"
            max-width="180px"
            max-height="180px"
          />
        </v-row>
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <p style="font-size: 18px" class="headtitle">แก้ไขข้อมูลผู้ใช้งาน</p>
          <!-- <pre>{{questionName}}</pre> -->
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="user.name"
                placeholder="ชื่อ - นามสกุล"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="user.rank"
                placeholder="ยศ"
                :rules="rules.required"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                v-model="user.phone"
                placeholder="เบอร์โทรศัพท์"
                :rules="rules.phone"
                counter="10"
                v-mask="'##########'"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-select
                v-model="user.userType"
                :items="itemsUserType"
                placeholder="ประเภทผู้ใช้งาน"
              ></v-select>
            </v-col>
            <v-col v-if="user.userType == '2'" cols="12" md="6" sm="6" xs="12">
              <v-autocomplete
                placeholder="เลือกภาค"
                v-model="user.area"
                :items="items2"
                item-text="group"
                item-value="group"
                :rules="rules.required"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="user.userType == '3'" cols="12" md="6" sm="6" xs="12">
              <v-autocomplete
                placeholder="เลือกจังหวัด"
                v-model="user.province"
                :items="items3"
                item-text="province"
                item-value="province"
                :rules="rules.required"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="user.userType == '4'" cols="12" md="6" sm="6" xs="12">
              <v-autocomplete
                placeholder="เลือกสถานี"
                v-model="user.station"
                :items="items"
                item-text="station"
                item-value="station"
                :rules="rules.required"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-btn color="primary" @click="submit()">บันทึก</v-btn>
          <v-btn @click="cancel()" class="ml-2">ย้อนกลับ</v-btn>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      checkValidate: "", //ห้ามลบ
      lazy: "", //ห้ามลบ
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน"],
        password: [(v) => !!v || "Password is required"],
        image: [(v) => !!v || "Image is required"],
        name: [(v) => !!v || "Name is required"],
        sername: [(v) => !!v || "Sername is required"],
        duty: [(v) => !!v || "Duty is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        selectRules: [(val) => !!val || "กรุณากรอกข้อมูล"],
        maxlength13: [
          (v) => v.length <= 13 || "Max 13 characters",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
        age: [
          (v) => v.length == 2 || "อายุต้องอยู่ระหว่าง 12-65 ปี เท่านั้น",
          (v) => v >= 12 || "อายุต้องอยู่ระหว่าง 12-65 ปี เท่านั้น",
          (v) => v <= 65 || "อายุต้องอยู่ระหว่าง 12-65 ปี เท่านั้น",
        ],
        phone: [
          (v) =>
            (v.length <= 10 && v.length >= 9) ||
            "หมายเลขโทรศัพท์ต้องมี 9-10 ตัวเท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน",
        ],
        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
      },
      village: "",
      station: "",
      district: "",
      subDistrict: "",
      province: "",
      dropdown: "",
      group: "",
      area: [],
      items: [],
      items2: [],
      items3: [],
      items4: [],
      selected: false,
      itemsUserType: [
        { text: "ADMIN", value: "99" },
        { text: "ทั่วประเทศ", value: "1" },
        { text: "ระดับภาค", value: "2" },
        { text: "ระดับจังหวัด", value: "3" },
        { text: "ระดับสถานี", value: "4" },
      ],
      user: {
        id: "",
        name: "",
        phone: "",
        station: "",
        userType: "",
        area: "",
        province: "",
      },
    };
  },
  async created() {
    this.searchDropdown();
    var data = JSON.parse(Decode.decode(localStorage.getItem("Userdata")));
    console.log(data);
    this.user.id = data.id;
    const response = await this.axios.get(
      `${process.env.VUE_APP_API}/user/getOne/` + this.user.id
    );
    console.log("response", response.data.data);
    var getuser = response.data.data;
    this.user.name = getuser.name;
    this.user.rank = getuser.rank;
    this.user.phone = getuser.phone;
    this.user.userType = getuser.userType;
    this.user.station = getuser.station;
    this.user.area = getuser.area;
    this.user.province = getuser.province;
  },
  methods: {
    // goToNext(dropdown) {
    //   console.log("print", dropdown);
    //   this.selected = true;
    //   this.station = dropdown.station;
    //   this.village = dropdown.village;
    //   this.district = dropdown.district;
    //   this.subDistrict = dropdown.subDistrict;
    //   this.province = dropdown.province;
    //   this.group = dropdown.group;
    //   // this.question1.push({
    //   //   station: dropdown.station,
    //   //   village: dropdown.village,
    //   //   district: dropdown.district,
    //   //   subDistrict: this.subDistrict,
    //   //   province: this.province,
    //   // })
    //   console.log(
    //     "logggg",
    //     this.station,
    //     this.village,
    //     this.district,
    //     this.subDistrict,
    //     this.province,
    //     this.group
    //   );

    //   this.addRow1();
    //   this.addRow2();
    //   this.addRow3();
    //   this.addRow4();
    //   this.addRow5();
    //   this.addRow6();
    //   this.addRow7();
    //   this.addRow8();
    // },
    cancel() {
      this.$router.push("ManageUser");
    },
    async searchDropdown() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);

      this.items2 = Array.from(
        new Set(this.items.map((a) => a.group.trim()))
      ).map((group) => {
        return this.items.find((a) => a.group.trim() === group.trim());
      });
      this.items3 = Array.from(
        new Set(this.items.map((a) => a.province.trim()))
      ).map((province) => {
        return this.items.find((a) => a.province.trim() === province.trim());
      });

      console.log(this.items2);

      this.items4 = response.data.data;
      // this.items4.forEach((item) => {
      //   item.searchText = item.station + " " + item.village;
      // });
    },
    async submit() {
      // this.user.station = this.dropdown.station;
      // this.user.province = this.dropdown.province;
      // this.user.group = this.dropdown.group;

      if (this.$refs.createForm.validate(true)) {
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/user/update`,
          this.user
        );
        console.log("response", response);
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "บันทึกข้อมูลสำเร็จ",
        });
        this.$router.push("ManageUser");
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอกข้อมูล",
        });
      }
    },
    resetValidation() {
      this.$refs.createForm.resetValidation();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>